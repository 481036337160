import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const BrowserFrame = () => (
  <BrowserContainer>
    <div className="container">
      <div className="top">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  </BrowserContainer>
);

const BrowserContainer = styled.div`
  .container {
    border: 3px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .top {
    padding: 10px;
    background: #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .dot {
    margin: 2px;
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
`;

export default BrowserFrame;
