import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import BoxRows from 'components/box/box-rows/box-rows';
import Box from 'components/box/box';
import Img from 'gatsby-image';
import { FullWidthImg } from 'components/image-wrappers';
import BrowserFrame from 'components/browser-frame';
import Title from 'components/title';
import Layout from 'components/layout';
import Modal from 'containers/modal';
import MEDIA from 'helpers/mediaTemplates';
import {
  TextUnderline,
  Body,
  Description,
  StickyText,
  NavigationWrapper,
  NavButtons,
  NavSubText
} from 'components/GlobalComponents';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
// import SEO from "../components/seo"

const ImgTitle = styled.h1`
  z-index: 2;
  font-size: 2.5em;
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  max-width: 1050px;
  padding: 0 1rem;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
`;

const BgImgContainer = styled.div`
  height: 600px;
`;

const SmImgContainer = styled.div`
  height: 300px;
`;

const ImgWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  margin: 1em auto;
  padding: 0 1em;
`;

const ScreenImgWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  margin: 1em auto;
  // padding-top: 4em;
`;

const BodyImgWrapper = styled(ImgWrapper)`
  max-width: 700px;
`;

// const AnimatedContainer = posed(BodyImgWrapper)({
//   enter: { opacity: 1, y: 0, delay: 500, delayChildren: 500, transition: { y: { ease: 'easeInOut', duration: 800 }, duration: 300 }},
//   exit: { opacity: 0.1, y: '100px'},
// });

const Project = ({ data, pageContext }) => {
  const project = data.projectsJson;
  const [ next, nextTitle ] = ["/cookingux", "The Rising Rose: UX Cooking Case Study"];

  return (
    <Layout>
      <BgImgContainer>
        <FullWidthImg
          key={project.gallery.hero.id}
          opacity={0.25}
          fluid={project.gallery.hero.childImageSharp.fluid}
        />
        <ImgTitle>{project.title}</ImgTitle>
      </BgImgContainer>
      <ProjectBox>
        {/* <Stack>
          <StackItem>
            <Title as="h3" size="large">
              Role
            </Title>
            {project.role}
          </StackItem>
          <StackItem>
            <Title as="h3" size="large">
              Context
            </Title>
            {project.context}
          </StackItem>
          <StackItem>
            <Title as="h3" size="large">
              Year
            </Title>
            {project.year}
          </StackItem>
        </Stack> */}
        <ProjectInfo>
          {/* <Title as="h2" size="large">
            Brief
            <TextUnderline centered/>
          </Title> */}
          <Description
            dangerouslySetInnerHTML={{
              __html: project.content.childMarkdownRemark.html,
            }}
          />
        </ProjectInfo>
      </ProjectBox>

      <ScreensContainer>
        <ImgWrapper>
          <Img fluid={project.gallery.laptopImage.childImageSharp.fluid} />
        </ImgWrapper>
        <ScreenImgWrapper>
          <Img fluid={project.gallery.phoneImage.childImageSharp.fluid} />
        </ScreenImgWrapper>
      </ScreensContainer>

      <BoxRows reverse>
        <StickyText height={300}>
          <Title as="h3" size="large">
            An Elegant Design
          </Title>
          <TextUnderline />
          <Body>As the Front-end Developer, I was responsible of designing and building the entire UX and UI for the new website, redefining the User Experience and studying new interactions between the User and the Interface.

One of the most exciting experience was integrating the entire front-end system with the Gatbsy framework and the change page animation.</Body>
        </StickyText>

        <>
          <ImgWrapper>
            <BrowserFrame />
            <Modal
              key={project.gallery.fullPage1.id}
              image={project.gallery.fullPage1.childImageSharp.fluid}
            >
              <Img fluid={project.gallery.fullPage1.childImageSharp.fluid} />
            </Modal>
          </ImgWrapper>
        </>
      </BoxRows>

      <>
        <FullWidthImg fluid={project.gallery.ipadImage.childImageSharp.fluid} />
      </>

      <BoxRows>
        <StickyText height={400}>
          <Title as="h3" size="large">
            {project.bodyHeader}
          </Title>
          <TextUnderline />
          <Body>{project.body}</Body>
        </StickyText>
        <>
          <ImgWrapper>
            <BrowserFrame />
            <Modal
              key={project.gallery.fullPage2.id}
              image={project.gallery.fullPage2.childImageSharp.fluid}
            >
              <Img fluid={project.gallery.fullPage2.childImageSharp.fluid} />
            </Modal>
          </ImgWrapper>
        </>
      </BoxRows>

      <SmImgContainer>
        <FullWidthImg fluid={project.gallery.endImage.childImageSharp.fluid} />
      </SmImgContainer>

      <NavigationWrapper background={'#f7d68b'}>
        <Link to={next}>
          <NavButtons line>
            <NavSubText>Next &#8594;</NavSubText>
            <Title as="span">{nextTitle}</Title>
          </NavButtons>
        </Link>
      </NavigationWrapper>
    </Layout>
  );
};

const ScreensContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1050px;
  margin: 1em auto;
  padding: 4em 0;
  border-top: 1px solid #e5e5e5;
`;

const ProjectBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
  width: 100%;
  max-width: 1250px;
`;

const ProjectInfo = styled.div`
  text-align: center;
  line-height: 1.6;
  width: 100%;
  max-width: 700px;
  padding-top: 3em;

  // ${MEDIA.DESKTOP`
  //   flex-direction: column;
  //   width: 100%;
  //   max-width: 100vw;
  // `}
`;

const ProjectType = styled.h3`
  margin: 2em auto;
  white-space: pre-wrap;
`;

// const AnimatedContainer = posed(BodyImgWrapper)({
//   enter: {
//     opacity: 1,
//     transition: {
//       duration: 200,
//       delay: 1000
//     },
//   },
//   exit: {
//     opacity: 0,
//   }
// });

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    # site {
    #   siteMetadata {
    #     title
    #     author
    #   }
    # }
    projectsJson(slug: { eq: $slug }) {
      title
      year
      role
      context
      bodyHeader
      body
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        hero {
          childImageSharp {
            fluid(maxWidth: 850, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        fullPage1 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        fullPage2 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        phoneImage {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        laptopImage {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ipadImage {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        endImage {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Project;
